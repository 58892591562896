import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit {
  @Input() value?: boolean;
  @Input() disabled = false;
  @Input() @HostBinding('class.locked') locked = false;
  private isRegister = false;

  constructor(private cdr: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {};

  ngOnInit() {
    this.emitChange();
  }

  valueChange(state: boolean) {
    if (!this.disabled && this.isRegister) {
      this.value = state;
      this.onChange(this.value);
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched() {}

  emitChange() {
    this.onChange(this.value);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.isRegister = true;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(obj: boolean): void {
    if (!this.disabled && this.isRegister) {
      this.value = obj;
      this.cdr.detectChanges();
    }
  }
}
