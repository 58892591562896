import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale, plLocale } from 'ngx-bootstrap/locale';

defineLocale('pl', plLocale);
defineLocale('es', esLocale);

const ALLOWED_LANGUAGES = ['pl', 'es'];

@Injectable({
  providedIn: 'root',
})
export class LocalizeService {
  constructor(
    private readonly localeService: BsLocaleService,
    private readonly translateService: TranslateService,
  ) {
    translateService.onLangChange.subscribe(() => {
      localeService.use(this.language);
    });
  }

  get language() {
    return ALLOWED_LANGUAGES.includes(this.translateService.currentLang) ? this.translateService.currentLang : 'en';
  }

  init() {
    this.localeService.use(this.language);
  }
}
