import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ImportUploaderComponent } from '@ui/common';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { BaseModalComponent } from '@ui/common/modals';
import { InlineToastComponent } from '@ui/features';
import { SvgIconComponent } from 'angular-svg-icon';

import { VisitorsImportApartmentDefaultComponent } from './visitors-import-apartment-default/visitors-import-apartment-default.component';
import { VisitorsImportApartmentErrorsComponent } from './visitors-import-apartment-errors/visitors-import-apartment-errors.component';
import { VisitorsImportApartmentFinishComponent } from './visitors-import-apartment-finish/visitors-import-apartment-finish.component';
import { VisitorsImportApartmentWarningsComponent } from './visitors-import-apartment-warnings/visitors-import-apartment-warnings.component';
import { VisitorsImportModalApartmentComponent } from './visitors-import-modal-apartment.component';

@NgModule({
  declarations: [
    VisitorsImportModalApartmentComponent,
    VisitorsImportApartmentDefaultComponent,
    VisitorsImportApartmentFinishComponent,
    VisitorsImportApartmentErrorsComponent,
    VisitorsImportApartmentWarningsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InlineToastComponent,
    ImportUploaderComponent,
    BaseModalComponent,
    ButtonRectangleComponent,
    SvgIconComponent,
  ],
})
export class VisitorsImportModalApartmentModule {}
