import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppModules } from '@smarttypes/core';
import { TourAnchorNgxBootstrapDirective, TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { Observable } from 'rxjs';

import { MenuItemComponent } from '../menu-item/menu-item.component';

export interface IMenuItem {
  label: string;
  icon: string;
  path: string;
  module?: AppModules;
  counter?: Observable<number>;
  totalCounter?: Observable<number>;
  children?: IMenuItemChild[];
  submenu?: boolean;
  unlockRequired?: boolean;
  disabled?: boolean;
  allowForTrialUsers?: boolean;
  badge?: 'beta' | 'new-ai' | string;
  warning?: Observable<boolean>;
  canDisplay?: boolean;
  tourAnchor?: string;
}

export interface IMenuItemChild {
  label: string;
  path: string;
}

@Component({
  selector: 'sh-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  standalone: true,
  imports: [CommonModule, MenuItemComponent, TourAnchorNgxBootstrapDirective, TourNgxBootstrapModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuGroupComponent {
  @Input() items: IMenuItem[] = [];
}
