export enum SmartJourneyVariableEnum {
  GuestName = 'GuestName',
  LastName = 'LastName',
  ApartmentName = 'ApartmentName',
  ReservationFrom = 'ReservationFrom',
  ReservationTo = 'ReservationTo',
  ReservationNo = 'ReservationNo',
  AccountName = 'AccountName',
  // CheckInDate = 'Check-in date',
  CheckInTime = 'CheckInTime',
  // CheckInToTime = 'Check-in to time',
  CheckOutTime = 'CheckOutTime',
  // StreetAndHouseNumber = 'Street and house number',
  // City = 'City',
  // PostalCode = 'Postal Code',
  // Country = 'Country',
  // ApartmentNumber = 'Apartment number',
  FloorNumber = 'FloorNumber',
  CheckInLink = 'CheckInLink',
  GaLink = 'GaLink',
  // GuestBookLink = 'GuestBook link',
  // EntryInstructionsLink = 'EntryInstructionsLink',
  // ContactUsLink = 'Contact us link',
  // CompanyName = 'Company Name',
}
