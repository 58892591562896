import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SmartLoggerService } from 'angular-v2-services';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';

@Injectable()
export class RetryTimeoutInterceptor implements HttpInterceptor {
  readonly toastr = inject(ToastrService);
  readonly #logger: SmartLoggerService = SmartLoggerService.create('RetryTimeoutInterceptor');
  readonly #maxRetries = 3;
  readonly #retryDelayMs = 1000;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('/smart-assistant/client')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, retryCount) => {
            if (retryCount >= this.#maxRetries || !(error instanceof HttpErrorResponse)) {
              this.#logger.error(`Request ${request.url} failed after ${this.#maxRetries} retries.`);
              if (error?.error?.message) {
                this.toastr.error(error.error.message);
              }
              return throwError(() => error);
            }

            if (error.status === 500) {
              retryCount++;
              this.#logger.warn(
                `Request ${request.url} failed. Retrying (${retryCount}/${this.#maxRetries}) after ${this.#retryDelayMs}ms...`,
              );
              return timer(this.#retryDelayMs);
            }

            return throwError(() => error);
          }),
        ),
      ),
    );
  }
}
