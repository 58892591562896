import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlagIconComponent, GrabberComponent } from '@ui/common';
import { ButtonRectangleComponent, ButtonSquareComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, FormComponent } from '@ui/common/forms';
import { FileLibraryDirective } from '@ui/features';
import { SvgIconComponent } from 'angular-svg-icon';
import { SortablejsModule } from 'nxt-sortablejs';

import { FileGroupComponent } from './file-group/file-group.component';
import { FileItemComponent } from './file-item/file-item.component';
import { FormFieldHintComponent } from './form-field-hint/form-field-hint.component';
import { FormFieldLanguagesHintComponent } from './form-field-languages-hint/form-field-languages-hint.component';
import { FormSectionComponent } from './form-section/form-section.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SortablejsModule,
    ButtonSquareComponent,
    GrabberComponent,
    FlagIconComponent,
    FileLibraryDirective,
    ButtonRectangleComponent,
    ErrorMessageComponent,
    SvgIconComponent,
    FormComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    FileGroupComponent,
    FileItemComponent,
    FormSectionComponent,
    FormFieldLanguagesHintComponent,
    FormFieldHintComponent,
  ],
  exports: [
    FileGroupComponent,
    FileItemComponent,
    FormSectionComponent,
    FormFieldLanguagesHintComponent,
    FormFieldHintComponent,
  ],
})
export class ConfiguratorComponentsModule {}
