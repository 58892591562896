export enum SmartJourneyReportStatusEnum {
  done = 'done',
  created = 'created',
  error = 'error',
  planned = 'planned',
  automation = 'automation',
  unnecessary = 'unnecessary',
  inProgress = 'inProgress',
  waiting = 'waiting',
  outdated = 'outdated',
  earlierStepRequired = 'earlierStepRequired',
}
