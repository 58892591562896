import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { KnowledgeDatabaseStatus, SmartCoreAssistantStatus } from '@smart-assistant/types/v2';
import { ToastService } from '@ui/features';
import { SmartLoggerService } from 'angular-v2-services';
import { BehaviorSubject, delay, forkJoin, map, Observable, ReplaySubject, shareReplay, switchMap, tap } from 'rxjs';

import { AssistantService } from './services/assistant/assistant.service';
import { ConfigurationService } from './services/configuration/configuration.service';
import { KnowledgeService } from './services/knowledge/knowledge.service';
import { TextService } from './services/text/text.service';
import { SmartAssistantConfig } from './smart-assistant.config.interface';
import { SmartAssistantConfiguration, SmartAssistantServices } from './smart-assistant.types';

export class SmartAssistantService {
  static #serviceStatus = new BehaviorSubject<SmartCoreAssistantStatus>(SmartCoreAssistantStatus.INACTIVE);
  readonly services: SmartAssistantServices;
  readonly toastService = inject(ToastService);
  readonly #logger = SmartLoggerService.create(SmartLoggerService.name);
  #isAuthenticated = false;
  readonly #pendingUpdate = new BehaviorSubject<boolean>(false);
  readonly #status = {
    status: new ReplaySubject<{
      status: SmartCoreAssistantStatus;
      extraKnowledgeStatus: KnowledgeDatabaseStatus;
    }>(1),
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: SmartAssistantConfig,
  ) {
    this.services = {
      configuration: new ConfigurationService(httpClient),
      knowledge: new KnowledgeService(httpClient),
      text: new TextService(httpClient, config),
      assistant: new AssistantService(httpClient),
    };

    this.#logger.debug('SmartAssistantService created');
  }

  get status$() {
    return this.#status.status.asObservable();
  }

  get pendingUpdate$() {
    return this.#pendingUpdate.asObservable();
  }

  get isAuthenticated(): boolean {
    return this.#isAuthenticated;
  }

  set isAuthenticated(value: boolean) {
    this.#isAuthenticated = value;
    if (this.#isAuthenticated) {
      this.#initService();
    }
  }

  get serviceStatusValue() {
    return SmartAssistantService.#serviceStatus.getValue();
  }

  get isUpdatePending() {
    return this.#pendingUpdate.getValue();
  }

  get serviceStatus$() {
    return SmartAssistantService.#serviceStatus.asObservable();
  }

  static setServiceStatus(value: SmartCoreAssistantStatus) {
    SmartAssistantService.#serviceStatus.next(value);
  }

  setPendingUpdate(value: boolean) {
    this.#pendingUpdate.next(value);
  }

  getConfiguration(updateStatus = false): Observable<SmartAssistantConfiguration> {
    return forkJoin([this.services.configuration.getStatus(), this.services.knowledge.getStatus()]).pipe(
      map(([status, extraKnowledgeStatus]) => {
        return {
          status,
          extraKnowledgeStatus,
        };
      }),
      tap(rs => {
        SmartAssistantService.setServiceStatus(
          rs.status === SmartCoreAssistantStatus.ACTIVE
            ? SmartCoreAssistantStatus.ACTIVE
            : SmartCoreAssistantStatus.INACTIVE,
        );

        if (updateStatus) {
          this.#status.status.next(rs);
        }
      }),
    );
  }

  toggleSmartAssistant(value: boolean) {
    return this.services.configuration
      .setStatus(value ? SmartCoreAssistantStatus.ACTIVE : SmartCoreAssistantStatus.PAUSED)
      .pipe(
        switchMap(() => this.getConfiguration(true)),
        map(rs => rs.status),
      );
  }

  toggleExtraKnowledge(value: boolean) {
    return this.services.knowledge
      .setStatus(value ? KnowledgeDatabaseStatus.READY : KnowledgeDatabaseStatus.PAUSED)
      .pipe(
        switchMap(() => this.getConfiguration(true)),
        map(rs => rs.extraKnowledgeStatus),
      );
  }

  /**
   * Initializes the service by subscribing to the configuration settings.
   * Updates the service status upon receiving the configuration.
   *
   * @return {void} No return value.
   */
  #initService() {
    this.getConfiguration(true).subscribe();
    // @TODO destroy on logout
    this.status$
      .pipe(
        map(rs => rs.extraKnowledgeStatus),
        tap(status => {
          if ([KnowledgeDatabaseStatus.PROCESSING, KnowledgeDatabaseStatus.QUEUED].includes(status)) {
            if (this.isUpdatePending) {
              return;
            }
            this.setPendingUpdate(true);
            this.toastService.custom({
              message: 'SH.AI_EXTRA_KNOWLEDGE.PROCESSING_TOAST',
              pending: true,
            });
          } else if (this.isUpdatePending) {
            this.setPendingUpdate(false);
            this.toastService.clear();
            this.toastService.custom({
              message: 'SH.AI_EXTRA_KNOWLEDGE.UPDATE_DONE_TOAST',
              icon: 'icons-checkmark-filled',
            });
          }
        }),
        delay(30000),
        switchMap(() => this.getConfiguration(true)),
        shareReplay(1),
      )
      .subscribe();
  }
}
