<ui-form
  label="{{ isProperty ? ('SH.FORM_AVATAR.PROPERTY_LABEL' | translate) : ('SH.FORM_AVATAR.LABEL' | translate) }}">
  <div class="picture">
    <ui-avatar [object]="avatar" [rounded]="avatarRounded" initials="S" size="xl" type="initialsUser"></ui-avatar>
    <div class="picture-text">
      <div *ngIf="avatar; else noAvatar" class="actions">
        <span (fileChange)="onChange($event)" [library]="library" class="link" uiFileLibrary>{{
            isProperty ? ('SH.FORM_AVATAR.CHANGE_LOGO' | translate) : ('SH.FORM_AVATAR.CHANGE_PHOTO' | translate)
          }}</span>
        <span class="separator">•</span>
        <span (click)="onRemove()" class="link">{{
            isProperty ? ('SH.FORM_AVATAR.REMOVE_LOGO' | translate) : ('SH.FORM_AVATAR.REMOVE_PHOTO' | translate)
          }}</span>
      </div>
      <ng-template #noAvatar>
        <h6 (fileChange)="onChange($event)" [library]="library" folder="Avatars" uiFileLibrary>
          {{ isProperty ? ('SH.FORM_AVATAR.ADD_LOGO' | translate) : ('SH.FORM_AVATAR.ADD_PHOTO' | translate) }}
        </h6>
      </ng-template>
      <p *ngIf="!isProperty">{{ 'SH.FORM_AVATAR.DESCRIPTION' | translate }}.</p>
    </div>
  </div>
</ui-form>
