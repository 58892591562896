import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PoweredByComponent } from '@ui/common';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { provideNgxMask } from 'ngx-mask';

import { NotificationBarComponent } from '../components/layout/notification-bar/notification-bar.component';
import { MenuGroupComponent } from '../components/layout/sidebar/menu-group/menu-group.component';
import { SidebarComponent } from '../components/layout/sidebar/sidebar.component';
import { SidebarBackdropComponent } from '../components/layout/sidebar-backdrop/sidebar-backdrop.component';
import { TopBarComponent } from '../components/layout/top-bar/top-bar.component';
import { TopNavigationComponent } from '../components/layout/top-navigation/top-navigation.component';
import { OnboardingComponent } from '../components/onboarding/onboarding.component';
import { PageTitleStrategy } from '../core/page-title-strategy';
import { DirectivesModule } from '../directives/directives.module';
import { VisitorsConfiguratorModule } from '../modules/visitors-configurator/visitors-configurator.module';
import { VisitorsListModule } from '../modules/visitors-list/visitors-list.module';
import { LocalizeService } from '../services/localize.service';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ViewsRoutingModule } from './views-routing.module';

@NgModule({
  declarations: [DashboardViewComponent, NotFoundComponent],
  imports: [
    CommonModule,
    ViewsRoutingModule,
    ReactiveFormsModule,
    VisitorsConfiguratorModule,
    TranslateModule,
    PoweredByComponent,
    TopBarComponent,
    SidebarComponent,
    MenuGroupComponent,
    TopNavigationComponent,
    SidebarBackdropComponent,
    DirectivesModule,
    VisitorsListModule,
    OnboardingComponent,
    NotificationBarComponent,
    ModalModule.forRoot(),
  ],
  exports: [DashboardViewComponent],
  providers: [
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    LocalizeService,
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
    provideNgxMask(),
  ],
})
export class ViewsModule {
  constructor(private readonly localizeService: LocalizeService) {
    localizeService.init();
  }
}
