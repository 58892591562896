import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApartmentBadgeComponent,
  AvatarComponent,
  PageTitleComponent,
  StarIconComponent,
  TabsComponent,
  TabsPageComponent,
} from '@ui/common';
import { ButtonCircleComponent, ButtonPillComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import {
  CheckboxButtonsComponent,
  CheckboxComponent,
  FormComponent,
  RadioButtonsComponent,
  SearchButtonComponent,
  SelectComponent,
} from '@ui/common/forms';
import { BaseModalComponent } from '@ui/common/modals';
import {
  BannerComponent,
  CopyTextButtonComponent,
  EmptyComponent,
  ImportedSignComponent,
  ReservationStatusComponent,
  RoomBadgeComponent,
  StayTimelineComponent,
  VisitorsChannelsComponent,
} from '@ui/features';
import { SmartUiControlModule } from '@ui/packages/smart-ui-control';
import { NavigationComponent } from '@widgets/front-common/widget';
import { SvgIconComponent } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { FullNamePipe, SwitchToMobileValuePipe } from 'angular-v2-pipes';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { environment } from '../../../environments/environment';
import { SelectRoomComponent } from '../../components/select-room/select-room.component';
import { StatusDotComponent } from '../../components/status-dot/status-dot.component';
import { DirectivesModule } from '../../directives/directives.module';
import { OwnersGuestItemComponent } from './owners-guest-item/owners-guest-item.component';
import { OwnersItemComponent } from './owners-item/owners-item.component';
import { VisitorsExportModalComponent } from './visitors-export-modal/visitors-export-modal.component';
import { VisitorsFiltersComponent } from './visitors-filters/visitors-filters.component';
import { VisitorsImportModalApartmentModule } from './visitors-import-modal-apartment/visitors-import-modal-apartment.module';
import { GuestRoommatesComponent } from './visitors-item/guest-roommates/guest-roommates.component';
import { RegistrationFormPreviewComponent } from './visitors-item/registration-form-preview/registration-form-preview.component';
import { RoommatesModalComponent } from './visitors-item/roommates-modal/roommates-modal.component';
import { VisitorMissingFieldsComponent } from './visitors-item/visitor-missing-field/visitor-missing-fields.component';
import { VisitorsDetailsItemComponent } from './visitors-item/visitors-details-item/visitors-details-item.component';
import { VisitorsItemComponent } from './visitors-item/visitors-item.component';
import { JourneyAutomationBarComponent } from './visitors-item/visitors-journey-bar/journey-automation-bar/journey-automation-bar.component';
import { JourneyStatusItemComponent } from './visitors-item/visitors-journey-bar/journey-status-item/journey-status-item.component';
import { JourneyWarningBarComponent } from './visitors-item/visitors-journey-bar/journey-warning-bar/journey-warning-bar.component';
import { VisitorsJourneyBarComponent } from './visitors-item/visitors-journey-bar/visitors-journey-bar.component';
import { VisitorsItemSkeletonComponent } from './visitors-item-skeleton/visitors-item-skeleton.component';
import { VisitorsListComponent } from './visitors-list.component';
import { VisitorsToolbarComponent } from './visitors-toolbar/visitors-toolbar.component';

@NgModule({
  declarations: [
    VisitorsListComponent,
    VisitorsFiltersComponent,
    VisitorsItemComponent,
    VisitorsItemSkeletonComponent,
    VisitorsToolbarComponent,
    VisitorsDetailsItemComponent,
    VisitorsJourneyBarComponent,
    JourneyStatusItemComponent,
    JourneyWarningBarComponent,
    GuestRoommatesComponent,
    RegistrationFormPreviewComponent,
    VisitorsExportModalComponent,
    VisitorMissingFieldsComponent,
    RoommatesModalComponent,
    JourneyAutomationBarComponent,
    OwnersItemComponent,
    OwnersGuestItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    BsDatepickerModule.forRoot(),
    InfiniteScrollModule,
    BsDropdownModule,
    DirectivesModule,
    VisitorsImportModalApartmentModule,
    RouterLink,
    SelectRoomComponent,
    ApartmentBadgeComponent,
    SearchButtonComponent,
    FullNamePipe,
    PageTitleComponent,
    ImportedSignComponent,
    VisitorsChannelsComponent,
    EmptyComponent,
    AvatarComponent,
    FormControlDirective,
    RoomBadgeComponent,
    SmartUiControlModule.forRoot(environment.ui),
    ButtonRectangleComponent,
    StarIconComponent,
    BannerComponent,
    ButtonCircleComponent,
    SelectComponent,
    ReservationStatusComponent,
    CheckboxButtonsComponent,
    SwitchToMobileValuePipe,
    NavigationComponent,
    ButtonPillComponent,
    ButtonCircleComponent,
    FormComponent,
    BaseModalComponent,
    CheckboxComponent,
    StayTimelineComponent,
    CopyTextButtonComponent,
    RadioButtonsComponent,
    TabsComponent,
    TabsPageComponent,
    NgxTippyModule,
    SvgIconComponent,
    ReactiveFormsModule,
    StatusDotComponent,
  ],
  exports: [VisitorsListComponent, JourneyStatusItemComponent],
  providers: [BsDatepickerConfig, BsLocaleService],
})
export class VisitorsListModule {}
