import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SmartJourneyReportStatusEnum, SmartJourneyStepStatusEnum } from '@smarttypes/smart-journey';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'sh-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: ['./status-dot.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
})
export class StatusDotComponent {
  @Input() type: SmartJourneyStepStatusEnum | SmartJourneyReportStatusEnum | string =
    SmartJourneyStepStatusEnum.partial;

  get icon() {
    switch (this.type) {
      case SmartJourneyStepStatusEnum.pending:
      case SmartJourneyReportStatusEnum.planned:
        return 'icons-clock';
      case SmartJourneyStepStatusEnum.error:
        return 'icons-exclamation-mark';
      case SmartJourneyReportStatusEnum.automation:
        return 'icons-automation';
      case SmartJourneyStepStatusEnum.automation:
        return 'icons-clock';
      case SmartJourneyReportStatusEnum.earlierStepRequired:
        return 'icons-exclamation-mark-small';
      default:
        return 'icons-checkmark';
    }
  }
}
