<div class="banner">
  <svg-icon *ngIf="iconLeft" [src]="iconLeftPath" class="icon-left"></svg-icon>
  <div class="banner-content">
    <h6 *ngIf="header">{{ header }}</h6>
    <p *ngIf="text" [innerHTML]="text">{{ text }}</p>
    <p *ngIf="html" [innerHTML]="html"></p>
  </div>
  <div *ngIf="iconRight || buttonText" class="banner-actions">
    <svg-icon (click)="onIconClick()" *ngIf="iconRight && !buttonText" [class.clickable]="iconRightAction"
              [src]="iconRightPath" class="icon-right"></svg-icon>
    <button (click)="buttonClick.emit()" *ngIf="buttonText && !iconRight">
      <span>{{ buttonText }}</span>
    </button>
  </div>
</div>
