import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Timezones } from '@smarttypes/core';
import { IDefaultWidget, IMeta, IOpeningHoursWidget, IPlaceWidget, ISection, IWidget } from '@widgets/interfaces';
import { getAnalyticUniqueId } from 'angular-v2-utils';
import { first } from 'lodash';
import { BehaviorSubject, debounceTime, map, Observable, of, switchMap, tap } from 'rxjs';

import { OpeningHoursService } from '../opening-hours';

export interface IVisitorsPlaceConfig {
  meta: IMeta;
  sections: ISection[];
  currentSection?: ISection;
  sectionsParent?: ISection;
  sectionsOrder: string[];
  companyTimezone?: string;
}

export interface IVisitorsPlaceRoom extends IWidget {
  name: string | null;
  entryInstructions: string | null;
  description: string | null;
  image?: string;
  payment?: string;
}

export const SUBDOMAIN_TOKEN = new InjectionToken<string | null>('SUBDOMAIN_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class VisitorsPlaceService {
  private _roomId?: string;
  private _guestId?: string;
  private config?: IVisitorsPlaceConfig;
  private configSubject$ = new BehaviorSubject<IVisitorsPlaceConfig | null>(null);
  private sectionsMap: Map<string, ISection> = new Map();
  private widgetsMap: Map<string, IPlaceWidget> = new Map();

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly openingHoursService: OpeningHoursService,
    @Inject(SUBDOMAIN_TOKEN) private subdomain: string | null,
  ) {}

  private _companyId?: string;

  get companyId(): string | undefined {
    return this.config?.meta.companyId ?? undefined;
  }

  get $config(): Observable<IVisitorsPlaceConfig | null> {
    return this.configSubject$.asObservable().pipe(debounceTime(250));
  }

  get language(): string {
    return this.translateService.currentLang ?? 'en';
  }

  get timezone(): string {
    return this.config?.companyTimezone ?? Timezones.Etc_UTC;
  }

  get roomWidget(): IVisitorsPlaceRoom | undefined {
    const data = this.config?.sectionsParent?.data as IVisitorsPlaceRoom[];
    return first(data);
  }

  get mallOpeningHours(): boolean {
    // TODO we need a better way to find mall opening hours
    const openingHours = this.getSectionData('opening-hours') as IOpeningHoursWidget[];
    const mall = first(openingHours);
    if (mall) {
      return this.openingHoursService.getOpenDays(mall, this.timezone, '', true, true)?.isOpen ?? false;
    }
    return false;
  }

  setRoomId(id: string) {
    this._roomId = id;
  }

  setCompanyId(id: string) {
    this._companyId = id;
  }

  setGuestId(id: string) {
    this._guestId = id;
  }

  getWidgetById(id: string): Observable<IPlaceWidget | IDefaultWidget> {
    const key = `${id}${this.language}`;
    const cached = this.widgetsMap.get(key);
    if (cached) {
      return of(cached);
    }

    let params = new HttpParams().set('language', this.language);
    if (this.subdomain) {
      params = params.append('subdomain', this.subdomain);
    }
    return this.http
      .get<IPlaceWidget>(`visitors-place/meta/public/widget/${id}`, {
        params,
      })
      .pipe(
        tap(w => {
          this.widgetsMap.set(`${w._id as string}${this.language}`, w);
        }),
      );
  }

  openWidgetWithSubdomain(widget: IPlaceWidget) {
    if (widget?.subdomain) {
      window.location.assign(`//${widget.subdomain}.${window.location.hostname}/`);
      return;
    }
  }

  getWidgetByIdFromCachedConfig(id: string): Observable<IPlaceWidget | IDefaultWidget | undefined> {
    return of(this.configSubject$.getValue()).pipe(
      map(sections => {
        const s = [...(sections?.sections ?? [])];
        if (sections?.sectionsParent) {
          s.push(sections?.sectionsParent);
        }
        return s;
      }),
      map(sections => {
        return sections.reduce((aggr: (IPlaceWidget | IDefaultWidget)[], section: ISection) => {
          aggr.push(...(section.data as (IPlaceWidget | IDefaultWidget)[]));
          return aggr;
        }, []);
      }),
      map(widgets => widgets.find((w: IPlaceWidget | IDefaultWidget) => w._id === id)),
    );
  }

  search(q: string, language?: string, withData?: boolean) {
    let params = new HttpParams().set('language', language ?? this.language).set('q', q);
    if (withData) {
      params = params.append('withData', withData);
    }
    return this.http.get<IPlaceWidget[]>(`visitors-place/meta/public/search`, {
      params,
    });
  }

  getSectionWidgetsByCode(code: string, force = false, language?: string, withData?: boolean): Observable<IWidget[]> {
    if (!language) {
      language = this.language;
    }
    const key = `${code}${language}`;
    const cached = this.sectionsMap.get(key);
    if (!force && cached) {
      return of(this.#sortWidgets(cached, (cached?.data ?? []) as IWidget[]));
    }

    let params = new HttpParams().set('language', language);
    if (this._companyId) {
      params = params.append('companyId', this._companyId);
    }
    if (this._roomId) {
      params = params.append('roomId', this._roomId);
    }
    if (withData) {
      params = params.append('withData', withData);
    }
    if (this.subdomain) {
      params = params.append('subdomain', this.subdomain);
    }

    return this.http
      .get<IVisitorsPlaceConfig>(`visitors-place/meta/public/${code}`, {
        params,
      })
      .pipe(
        switchMap(config => {
          if (config.currentSection) {
            this.sectionsMap.set(key, config.currentSection);
            return of(this.#sortWidgets(config.currentSection, (config.currentSection?.data ?? []) as IWidget[]));
          }
          return of([]);
        }),
      );
  }

  getConfig(force = false, language?: string, withData?: boolean): Observable<IVisitorsPlaceConfig> {
    let params = new HttpParams().set('language', language ? language : this.language);
    if (this._companyId) {
      params = params.append('companyId', this._companyId);
    }
    if (this._roomId) {
      params = params.append('roomId', this._roomId);
    }
    if (this._guestId) {
      params = params.append('guestId', this._guestId);
    }
    if (withData) {
      params = params.append('withData', true);
    }
    if (this.subdomain) {
      params = params.append('subdomain', this.subdomain);
    }
    if (this.config && !force) {
      return of(this.config);
    }
    return this.http
      .get<IVisitorsPlaceConfig>(`visitors-place/meta/public`, {
        params,
      })
      .pipe(
        map(config => {
          if (withData) {
            config.sections.map(s => {
              s.data = this.#sortWidgets(s, (s.data ?? []) as IWidget[]);
            });
            return config;
          }
          return config;
        }),
        tap(config => {
          if (config) {
            this.config = config;
            this.configSubject$.next(this.config);
          }
        }),
      );
  }

  getSectionData(code: string) {
    const key = `${code}${this.language}`;
    const cached = this.sectionsMap.get(key);
    if (cached) {
      return this.#sortWidgets(cached, (cached?.data ?? []) as IWidget[]);
    }
    return [];
  }

  gaVisit() {
    return this.http.post(`public/metrics/ga-view`, {
      companyId: this.companyId,
      referralId: localStorage.getItem('gId') || getAnalyticUniqueId(),
    });
  }

  widgetClick(id: string, key = 'GAV2_WIDGET') {
    const url = `public/metrics/${key}_${id}`;
    return this.http
      .post(url, {
        companyId: this.companyId,
      })
      .subscribe();
  }

  #sortWidgets(section: ISection, widgets: IWidget[]) {
    return widgets.sort(
      (a, b) =>
        (section?.widgetsOrder ?? []).indexOf(a._id as string) - (section?.widgetsOrder ?? []).indexOf(b._id as string),
    );
  }
}
