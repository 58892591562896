import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BannerComponent } from '@ui/features';
import { showNotificationAnimation } from 'angular-v2-animations';
import { Subscription } from 'rxjs';

import { INotificationBar, NotificationBarService } from '../../../core/services/notification-bar.service';

@Component({
  selector: 'sh-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, BannerComponent],
  animations: [showNotificationAnimation],
})
export class NotificationBarComponent implements OnInit, OnDestroy {
  notifications: INotificationBar[] = [];
  private subscription: Subscription = new Subscription();

  constructor(private notificationBarService: NotificationBarService) {}

  ngOnInit() {
    this.subscription.add(
      this.notificationBarService.$notifications.subscribe(notifications => {
        this.notifications = notifications;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
