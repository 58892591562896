import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmartLoggerService } from 'angular-v2-services';
import { de, enUS, es, hr, it, pl, ru, uk } from 'date-fns/locale';
import { Locale } from 'date-fns/locale/types';
import { format, formatInTimeZone } from 'date-fns-tz';

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  readonly locales: Record<string, Locale> = { en: enUS, pl, es, de, ru, hr, uk, it };
  readonly logger = inject(SmartLoggerService);
  readonly translateService = inject(TranslateService);

  transform(value: Date | string | undefined | null, pattern = 'MMM d, y', timezone?: string): string | null {
    if (!value) {
      return null;
    }

    try {
      const date = typeof value === 'string' ? new Date(value) : value;
      const locale = this.#getLocale();
      return timezone ? formatInTimeZone(date, timezone, pattern, { locale }) : format(date, pattern, { locale });
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  #getLocale(): Locale {
    const language = this.translateService.currentLang;
    return this.locales[language] || enUS;
  }
}
